<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    plainText() {
      return this.block.fields.MultiRowText;
    },
  },
};
</script>

<template>
  <div class="_quote-container">
    <div class="_quote-text"> {{plainText}} </div>
  </div>
</template>

<style>
._quote-container {
  padding: 0 0.5rem;

  @media(--tabletAndDesktop) {
    padding-left: 0;
    padding-right: 0;
  }
}

._quote-text {
  width: calc(100% - 1rem);
  max-width: calc(800px);
  margin: auto;
  text-align: center;
  font-family: var(--font-inline);
  font-size: 20px;
  line-height: 1.2em;
  padding: 1rem 0;
  white-space: pre-wrap;
}

</style>
